import React, { Component } from 'react';
import { arrayOf, func, number, string } from 'prop-types';
import includes from 'ramda/src/includes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shallowCompare from 'react-addons-shallow-compare';
import uniq from 'ramda/src/uniq';
import append from 'ramda/src/append';
import filter from 'ramda/src/filter';

import { DEFAULT_IDS } from 'constants/Metrics';
import { userEventNames } from 'constants/usercom';

import MetricsItem from 'components/modals/metrics/MetricsItem';
import MetricsModalEmpty from 'components/modals/metrics/MetricsModalEmpty';
import MetricsSearch from 'components/modals/metrics/MetricsSearch';
import MetricType from 'types/MetricType';

const METRIC_MODAL_ID = 'METRIC_MODAL_ID';

class MetricsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMetricIds: this.props.selectedMetricIds,
        };

        this.handleDone = this.handleDone.bind(this);
        this.handleModalClick = this.handleModalClick.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.renderSeparator = this.renderSeparator.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    handleModalClick(e) {
        if (e.target.id === METRIC_MODAL_ID) {
            e.stopPropagation();
            this.props.onCloseModal();
        }
    }

    handleSelectChange({ id, selected }) {
        if (selected) {
            if (!includes(id, this.state.selectedMetricIds)) {
                this.setState(state => ({
                    selectedMetricIds: append(id, state.selectedMetricIds),
                }));
            }
        } else {
            this.setState(state => ({
                selectedMetricIds: filter(item => item !== id, state.selectedMetricIds),
            }));
        }
    }

    handleDone() {
        if (this.state.selectedMetricIds.length > 0) {
            this.props.onMetricSelectChange(this.state.selectedMetricIds);
            this.props.onCloseModal();
        }
        UsercomService.createEvent({ eventName: userEventNames.SCH_METRICS });
    }

    handleReset() {
        this.setState({
            selectedMetricIds: DEFAULT_IDS,
        });
        UsercomService.createEvent({ eventName: userEventNames.SCH_METRICS });
    }

    isSelected(metricId) {
        return includes(metricId, this.state.selectedMetricIds);
    }

    renderSeparator(metricType) {
        return (
            <div className="mg-margin-b-10 mg-margin-t-15">
                <h4>{metricType} metrics</h4>
            </div>
        );
    }

    renderItems(metricType) {
        return this.props.metrics
            .filter(metric => metric.type === metricType)
            .map(metric => (
                <MetricsItem
                    key={metric.id}
                    id={metric.id}
                    selected={this.isSelected(metric.id)}
                    name={metric.name}
                    onSelectChange={this.handleSelectChange}
                    description={metric.description}
                />
            ));
    }

    renderBody() {
        if (this.props.metrics.length > 0) {
            const metricTypes = uniq(this.props.metrics.map(metric => metric.type));
            return metricTypes.map(metricType => (
                <div key={metricType}>
                    {this.renderSeparator(metricType)}
                    <div className="uk-grid uk-grid-small">{this.renderItems(metricType)}</div>
                </div>
            ));
        } else {
            return <MetricsModalEmpty />;
        }
    }

    render() {
        return (
            <div
                id={METRIC_MODAL_ID}
                className="uk-modal uk-display-block uk-text-center mg-modal-metrics uk-open"
                onClick={this.handleModalClick}
            >
                <div className="uk-modal-dialog is-large uk-padding-remove fadeInDown animated-once uk-text-left">
                    <button className="mg-close" onClick={this.props.onCloseModal} type="button">
                        <FontAwesomeIcon icon="times" />
                    </button>
                    <div className="mg-padding-30">
                        <div className="uk-flex uk-flex-space-between uk-flex-wrap">
                            <h3 className="font-30 uk-text-bold uk-margin-remove">Choose SEO metrics to show</h3>
                            <div className="mg-margin-t-15 uk-visible-small uk-width-1-1" />
                            <MetricsSearch
                                metricFilter={this.props.metricFilter}
                                onMetricFilterChange={this.props.onMetricFilterChange}
                            />
                        </div>
                    </div>
                    <div className="sch-metrics-holder mg-border-t mg-padding-0-30">{this.renderBody()}</div>

                    <div className="mg-modal-footer uk-text-center">
                        <div className="mg-padding-0-30">
                            <button className="mg-btn is-white is-small" onClick={this.handleReset} type="button">
                                <FontAwesomeIcon icon="undo-alt" />
                                &nbsp;Reset to default
                            </button>
                            &nbsp;
                            <button
                                className="mg-btn is-green is-small"
                                disabled={this.state.selectedMetricIds.length < 1}
                                onClick={this.handleDone}
                                type="button"
                            >
                                <FontAwesomeIcon icon="check" />
                                &nbsp;Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MetricsModal.propTypes = {
    metricFilter: string.isRequired,
    metrics: arrayOf(MetricType).isRequired,
    onCloseModal: func.isRequired,
    onMetricFilterChange: func.isRequired,
    onMetricSelectChange: func.isRequired,
    selectedMetricIds: arrayOf(number).isRequired,
};

export default MetricsModal;
