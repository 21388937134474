import React from 'react';
import { func } from 'prop-types';
import { withVisibilityLogic } from 'mangools-react-components';

import MessageHolder from 'components/messages/MessageHolder';
import unavailableImg from 'images/unavailable-white.svg';

function AccessDeniedMessage(props) {
    return (
        <MessageHolder onClose={props.onClose}>
            <div className="mg-modal-header is-error">
                <img src={unavailableImg} alt="Oh no" width="100" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-24 uk-text-bold">Access denied</h3>
                <p className="uk-margin-top uk-margin-bottom">
                    We&apos;re sorry, but it looks like you are accessing a resource you have no rights to.
                </p>
            </div>
        </MessageHolder>
    );
}

AccessDeniedMessage.propTypes = {
    onClose: func.isRequired,
};

export default withVisibilityLogic(AccessDeniedMessage);
