import React from 'react';
import { func, string } from 'prop-types';
import { withVisibilityLogic } from 'mangools-react-components';

import unavailableImg from 'images/unavailable-white.svg';
import MessageHolder from 'components/messages/MessageHolder';
import Strings from 'constants/Strings';

function FailureMessage(props) {
    return (
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove fadeInDown animated-once">
            <div className="mg-modal-header is-error">
                <img src={unavailableImg} alt="Oh no" width="100" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-24 uk-text-bold">{props.header}</h3>
                {props.details ? <p className="uk-margin-top uk-margin-bottom color-grey">{props.details}</p> : null}
                <p className="uk-margin-top uk-margin-bottom color-grey">{Strings.messages.failure.footer_sorry}</p>
            </div>
        </MessageHolder>
    );
}

FailureMessage.propTypes = {
    details: string,
    header: string,
    onClose: func.isRequired,
};

FailureMessage.defaultProps = {
    details: null,
    header: 'Sorry, something went wrong',
};

export default withVisibilityLogic(FailureMessage);
