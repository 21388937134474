import React from 'react';
import { func } from 'prop-types';
import classnames from 'classnames';
import { withVisibilityLogic } from 'mangools-react-components';

import MessageHolder from 'components/messages/MessageHolder';
import SerpFeaturesImpactService from 'services/SerpFeaturesImpactService';

function SerpFeaturesImpactInfoMessage(props) {
    return (
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove fadeInDown animated-once">
            <div className="mg-modal-header">
                <h3 className="font-24 uk-text-bold color-black">SERP Features Impact</h3>
            </div>
            <div className="mg-modal-content">
                <div className="mg-alert is-info uk-text-left mg-margin-b-15 font-14">
                    <p>
                        Measures the <strong>impact of SERP Features</strong> on organic results engagement. The higher
                        is the impact, the lower is the engagement.
                    </p>
                </div>
                <div className="mg-results uk-padding-remove">
                    <div className="mg-results-tr uk-grid uk-grid-collapse">
                        <div className="mg-results-td uk-width-1-3">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(0))}>
                                <strong>0</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-2-3">{SerpFeaturesImpactService.getLabel(0)}</div>
                    </div>
                    <div className="mg-results-tr uk-grid uk-grid-collapse">
                        <div className="mg-results-td uk-width-1-3">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(1))}>
                                <strong>1</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-2-3">{SerpFeaturesImpactService.getLabel(1)}</div>
                    </div>
                    <div className="mg-results-tr uk-flex">
                        <div className="mg-results-td uk-width-2-">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(2))}>
                                <strong>2</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-1-3">{SerpFeaturesImpactService.getLabel(2)}</div>
                    </div>
                    <div className="mg-results-tr uk-flex">
                        <div className="mg-results-td uk-width-2-">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(3))}>
                                <strong>3</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-1-3">{SerpFeaturesImpactService.getLabel(3)}</div>
                    </div>
                    <div className="mg-results-tr uk-flex">
                        <div className="mg-results-td uk-width-2-">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(4))}>
                                <strong>4</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-1-3">{SerpFeaturesImpactService.getLabel(4)}</div>
                    </div>
                    <div className="mg-results-tr uk-flex">
                        <div className="mg-results-td uk-width-2-">
                            <span className={classnames('mg-rank', SerpFeaturesImpactService.getColor(5))}>
                                <strong>5</strong>
                            </span>
                        </div>
                        <div className="mg-results-td uk-width-1-3">{SerpFeaturesImpactService.getLabel(5)}</div>
                    </div>
                </div>
            </div>
        </MessageHolder>
    );
}

SerpFeaturesImpactInfoMessage.propTypes = {
    onClose: func.isRequired,
};

export default withVisibilityLogic(SerpFeaturesImpactInfoMessage);
