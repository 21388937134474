import React, { useCallback } from 'react';
import Urls from 'mangools-commons/lib/constants/Urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { withVisibilityLogic, useTrackMessageVisibilityEffect } from 'mangools-react-components';
import config from 'appConfig';

import mnglsImageWhiteTypo from 'images/mangools-logo-typo-white.svg';
import MessageHolder from 'components/messages/MessageHolder';

import { signInRedirectSelector } from 'selectors/routingSelectors';

import kwImage from 'images/kwfinder-icon.svg';
import serpwImage from 'images/serpwatcher-icon.svg';
import serpcImage from 'images/serpchecker-icon.svg';
import linkmImage from 'images/linkminer-icon.svg';
import spImage from 'images/siteprofiler-icon.svg';

import Emoji from 'components/reusable/Emoji';
import Preloader from 'components/reusable/Preloader';

import { gtmTrack } from 'actions/analyticsActions';
import { analyticsEvents, analyticsActions } from 'constants/analytics';

function NeedToSignInMessage() {
    const dispatch = useDispatch();
    const redirectRoute = useSelector(signInRedirectSelector);

    const onGtmTrack = useCallback(
        payload => {
            dispatch(gtmTrack(payload));
        },
        [dispatch],
    );

    useTrackMessageVisibilityEffect(analyticsEvents.LOGIN_MESSAGE, onGtmTrack);

    const handleCreateAccountClick = () => {
        onGtmTrack({
            action: analyticsActions.REGISTER,
            event: analyticsEvents.LOGIN_MESSAGE,
        });
    };

    const handleAlreadyRegisterClick = () => {
        onGtmTrack({
            action: analyticsActions.LOGIN,
            event: analyticsEvents.LOGIN_MESSAGE,
        });
    };

    return (
        <MessageHolder classNames="is-register">
            <div className="mg-modal-header is-mngls uk-padding-remove" />
            <div className="uk-flex uk-flex-wrap">
                <div className="uk-width-large-6-10 uk-flex uk-flex-middle uk-flex-center">
                    <div className="mg-modal-content">
                        <div className="uk-flex uk-flex-center mg-margin-t-30">
                            <Preloader text="" />
                        </div>
                        <h3 className="font-30 uk-text-bold mg-margin-t-30">
                            Get FREE access
                            <br />
                            with ALL the features
                        </h3>
                        <p className="font-16">
                            Get full access to all our juicy SEO tools. Register your Mangools account to activate your
                            free plan.
                        </p>
                        <div className="mg-margin-t-30">
                            <a
                                className="mg-btn is-green is-gradient"
                                href={`${config.MANGOOLS_URLS.REGISTER_NO_REDIRECT}?ref=msg-app-sch`}
                                onClick={handleCreateAccountClick}
                            >
                                <strong>Create a FREE account</strong>
                            </a>
                            <div className="font-14 color-grey mg-margin-t-10">
                                30 seconds and you&apos;re good to go, we promise&nbsp;
                                <Emoji symbol="🤞" label="crossed-fingers" />
                            </div>
                        </div>
                        <p className="mg-margin-t-30">
                            <a
                                className="font-14"
                                href={`${
                                    config.MANGOOLS_URLS.LOGIN_NO_REDIRECT
                                }?ref=msg-app-sch&redirect=${encodeURIComponent(redirectRoute)}`}
                                onClick={handleAlreadyRegisterClick}
                            >
                                Already have an account?
                            </a>
                        </p>
                    </div>
                </div>
                <div className="mg-bg-gradient-mngls uk-width-large-4-10 uk-text-left uk-visible-large">
                    <div className=" mg-padding-45 color-white">
                        <div className="uk-text-center mg-margin-b-45">
                            <img className="mg-margin-b-5" src={mnglsImageWhiteTypo} alt="Mangools logo" width="140" />
                            <div className="font-14">Juicy SEO tools you will love</div>
                        </div>
                        <ul className="mg-apps-list">
                            <li>
                                <a
                                    href={Urls.KWFINDER_LANDING_URL}
                                    className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <div className="color-white uk-flex uk-flex-middle">
                                        <div className="mg-app-icon mg-margin-r-10">
                                            <img src={kwImage} alt="logo" width="18" />
                                        </div>
                                        <div>
                                            <div className="font-16 uk-text-bold">KWFinder</div>
                                            <div className="font-14 color-white">Keyword Research</div>
                                        </div>
                                    </div>
                                    <span className="uk-hidden font-24">
                                        <FontAwesomeIcon
                                            icon="external-link-alt"
                                            className="color-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={Urls.SERPCHECKER_LANDING_URL}
                                    className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <div className="color-white uk-flex uk-flex-middle">
                                        <div className="mg-app-icon mg-margin-r-10">
                                            <img src={serpcImage} alt="logo" width="18" />
                                        </div>
                                        <div>
                                            <div className="font-16 uk-text-bold">SERPChecker</div>
                                            <div className="font-14 color-white">SERP Analysis</div>
                                        </div>
                                    </div>
                                    <span className="uk-hidden font-24">
                                        <FontAwesomeIcon
                                            icon="external-link-alt"
                                            className="color-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={Urls.SERPWATCHER_LANDING_URL}
                                    className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <div className="color-white uk-flex uk-flex-middle">
                                        <div className="mg-app-icon mg-margin-r-10">
                                            <img src={serpwImage} alt="logo" width="18" />
                                        </div>
                                        <div>
                                            <div className="font-16 uk-text-bold">SERPWatcher</div>
                                            <div className="font-14 color-white">Rank Tracking</div>
                                        </div>
                                    </div>
                                    <span className="uk-hidden font-24">
                                        <FontAwesomeIcon
                                            icon="external-link-alt"
                                            className="color-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={Urls.LINKMINER_LANDING_URL}
                                    className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <div className="color-white uk-flex uk-flex-middle">
                                        <div className="mg-app-icon mg-margin-r-10">
                                            <img src={linkmImage} alt="logo" width="18" />
                                        </div>
                                        <div>
                                            <div className="font-16 uk-text-bold">LinkMiner</div>
                                            <div className="font-14 color-white">Backlink Analysis</div>
                                        </div>
                                    </div>
                                    <span className="uk-hidden font-24">
                                        <FontAwesomeIcon
                                            icon="external-link-alt"
                                            className="color-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={Urls.SITEPROFILER_LANDING_URL}
                                    className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <div className="color-white uk-flex uk-flex-middle">
                                        <div className="mg-app-icon mg-margin-r-10">
                                            <img src={spImage} alt="logo" width="18" />
                                        </div>
                                        <div>
                                            <div className="font-16 uk-text-bold">SiteProfiler</div>
                                            <div className="font-14 color-white">SEO metrics &amp; Insights</div>
                                        </div>
                                    </div>
                                    <span className="uk-hidden font-24">
                                        <FontAwesomeIcon
                                            icon="external-link-alt"
                                            className="color-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </MessageHolder>
    );
}

export default withVisibilityLogic(React.memo(NeedToSignInMessage));
