import React from 'react';
import { func } from 'prop-types';
import { withVisibilityLogic } from 'mangools-react-components';

import MessageHolder from 'components/messages/MessageHolder';

function ShortcutsMessage(props) {
    return (
        /* eslint-disable max-len */
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove fadeInDown animated-once">
            <div className="mg-modal-content uk-text-left">
                <div className="mg-padding-30-0">
                    <h3 className="font-30 uk-text-bold uk-text-center uk-margin-remove">Keyboard shortcuts</h3>
                </div>
                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Load next SERP</strong>
                    </div>
                    <div className="font-14">
                        <span className="mg-input is-xsmall is-key">
                            <strong>N</strong>
                        </span>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press &quot;N&quot; to load next result page
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Toggle between results and snapshot</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">T</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press &quot;T&quot; to toggle between SERP table and original snapshot
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Show export modal</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">E</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press &quot;E&quot; to open up the export modal
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Show metrics modal</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">M</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press &quot;M&quot; to open up the selected metrics modal
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Focus compare domain input</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">C</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press &quot;C&quot; to focus comparison input and compare results with your competitors
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Load next SERP snapshot</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">&rarr;</strong>
                        &nbsp;or&nbsp;<strong className="mg-input is-xsmall is-key">N</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press right arrow or &quot;N&quot; to load the next SERP snapshot
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Load previous SERP snapshot</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">&larr;</strong>
                        &nbsp;or&nbsp;<strong className="mg-input is-xsmall is-key">P</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                    Press left arrow or &quot;P&quot; to load the previous SERP snapshot
                </div>

                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <div>
                        <strong>Close a dropdown / modal</strong>
                    </div>
                    <div className="font-14">
                        <strong className="mg-input is-xsmall is-key">Esc</strong>
                    </div>
                </div>
                <div className="mg-padding-15-0 font-14">
                    Press &quot;Esc&quot; to close any dropdown or modal window.
                </div>
            </div>
        </MessageHolder>
        /* eslint-enable max-len */
    );
}

ShortcutsMessage.propTypes = {
    onClose: func.isRequired,
};

export default withVisibilityLogic(ShortcutsMessage);
