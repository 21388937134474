import React from 'react';
import { func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withVisibilityLogic } from 'mangools-react-components';

import MessageHolder from 'components/messages/MessageHolder';

function NoConnectionMessage(props) {
    return (
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove fadeInDown animated-once">
            <div className="mg-modal-header is-error">
                <FontAwesomeIcon icon="plug" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-24 uk-text-bold">Connection problem</h3>
                <p className="uk-margin-top uk-margin-bottom">
                    Looks like you have <strong>lost connection</strong> to the server
                    <br />
                    or the <strong>request was blocked by a 3rd party application</strong>
                    &nbsp;(e.g. browser extension like adBlock).
                </p>
            </div>
        </MessageHolder>
    );
}

NoConnectionMessage.propTypes = {
    onClose: func.isRequired,
};

export default withVisibilityLogic(NoConnectionMessage);
