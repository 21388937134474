import 'config/initializer'; // Import all the styles, polyfills, etc.
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import FlagProvider from '@unleash/proxy-client-react';

import store from 'lib/store';
import AppContainer from 'components/AppContainer';
import { unleashConfig } from 'config/unleash';
// Put APP ROOT on window
window.APP_ROOT = document.getElementById('app');

render(
    <Provider store={store}>
        <FlagProvider config={unleashConfig}>
            <AppContainer />
        </FlagProvider>
    </Provider>,
    window.APP_ROOT,
);
